import { supabase } from "../supabase";

// Function to get the user ID based on the auth ID
const getUserIdFromAuthId = async (authId: string) => {
  try {
    if (!supabase) {
      console.error("Supabase is not initialized.");
      return null;
    }

    const { data, error } = await supabase
      .from("User")
      .select("user_id")
      .eq("auth_id", authId)
      .single();

    if (error) {
      console.error("Error fetching user ID:", error.message);
      return null;
    }

    return data?.user_id || null;
  } catch (error: any) {
    console.error("Error fetching user ID:", error.message);
    return null;
  }
};

// Function to create a new tenant, project, and add user as a project member
export const createUserAndTenant = async (authId: string) => {
  try {
    if (!supabase) {
      console.error("Supabase is not initialized.");
      return;
    }
    console.log("Creating user, tenant, and template project");

    // Step 1: Get the User ID from Auth ID
    const userId = await getUserIdFromAuthId(authId);
    if (!userId) {
      console.error("User ID not found.");
      return;
    }

    // Step 2: Create Tenant
    const { data: tenantData, error: tenantError } = await supabase
      .from("Tenant")
      .insert([{ name: "empty" }])
      .select();

    if (tenantError || !tenantData) {
      console.error("Error creating tenant:", tenantError?.message);
      return;
    }

    const tenantId = tenantData?.[0]?.id;

    // Step 3: Create User-Tenant Relation
    const { error: userTenantError } = await supabase
      .from("TenantUser")
      .insert([{ user: userId, tenant_id: tenantId, role: "admin" }])
      .select();

    if (userTenantError) {
      console.error("Error creating user-tenant relation:", userTenantError?.message);
      return;
    }

    // Step 4: Create Tenant Plan (14 days trial)
    const now = new Date();
    const endDate = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);
    const { error: tenantPlanError } = await supabase
      .from("TenantPlan")
      .insert([{ tenant_id: tenantId, plan_id: 1, end_date: endDate }])
      .select();

    if (tenantPlanError) {
      console.error("Error creating tenant plan relation:", tenantPlanError?.message);
      return;
    }

    // Step 5: Add Template Project for the User
    const { data: projectData, error: projectError } = await supabase
      .from("Project")
      .insert([{ name: "Template Project", user_id: userId, created_at: now, updated_at: now, template: true }])
      .select();

    if (projectError || !projectData) {
      console.error("Error creating template project:", projectError?.message);
      return;
    }

    const projectId = projectData?.[0]?.id;

    // Step 6: Clone Configuration and Input Parameters from Template
    const { data: projectTemplateData, error: projectTemplateError } = await supabase
      .from("Project")
      .select()
      .eq("name", "Template Use Case");

    if (projectTemplateError || !projectTemplateData) {
      console.error("Error fetching template project:", projectTemplateError?.message);
      return;
    }

    const projectTemplateId = projectTemplateData?.[0]?.id;

    // Clone Configuration
    const { data: configurationData, error: configurationError } = await supabase
      .from("Configuration")
      .select()
      .eq("project", projectTemplateId)
      .eq("name", "template");

    if (configurationError || !configurationData) {
      console.error("Error fetching configuration data:", configurationError?.message);
      return;
    }

    const { data: newConfigurationData, error: newConfigurationError } = await supabase
      .from("Configuration")
      .insert([{
        project: projectId,
        name: "current",
        configuration_data: configurationData?.[0]?.configuration_data,
        results_data: configurationData?.[0]?.results_data,
        created_at: now
      }])
      .select();

    if (newConfigurationError || !newConfigurationData) {
      console.error("Error creating new configuration:", newConfigurationError?.message);
      return;
    }

    const newConfigurationId = newConfigurationData?.[0]?.id;

    // Clone Input Parameters
    const { data: parameterData, error: parameterError } = await supabase
      .from("InputParameters")
      .select()
      .eq("configuration", configurationData?.[0]?.id);

    if (parameterError || !parameterData) {
      console.error("Error fetching input parameters:", parameterError?.message);
      return;
    }

    const { error: newInputParametersError } = await supabase
      .from("InputParameters")
      .insert([{
        project: projectId,
        parameters: parameterData?.[0]?.parameters,
        last_update: now,
        configuration: newConfigurationId
      }]);

    if (newInputParametersError) {
      console.error("Error creating new input parameters:", newInputParametersError?.message);
      return;
    }

    // Step 7: Add the User as a Member of the New Project
    const { error: projectMemberError } = await supabase
      .from("ProjectMembers")
      .insert([{ project_id: projectId, project_member: userId, role: "admin" }]);

    if (projectMemberError) {
      console.error("Error adding user as project member:", projectMemberError?.message);
      return;
    }

    console.log("User, tenant, and template project created successfully, and user added as project member.");

  } catch (error: any) {
    console.error("Error creating user, tenant, or project:", error.message);
  }
};
