import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Stack,
  Popover,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { supabase } from "../../../supabase";
import { useNavigate } from "react-router-dom";
import { User } from "@supabase/supabase-js";
import Loading from "../../../../src/assets/gifs/Loading.gif";
import i18n from "../../../i18n";

const BouncyIconBox = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: "50%",
  backgroundColor: "#CCCCCC", // Grey background color
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "#FFFFFF", // White text color
  position: "relative", // Add position relative to allow for positioning the popover
}));

const StyledLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  borderRadius: 12,
  display: "flex",
  padding: "6px 8px",
  color: "#000000DE",
  alignSelf: "stretch",
  fontWeight: "fontWeightBold",
  alignItems: "center",
  fontFamily: "Public Sans, sans-serif",
  fontStyle: "normal",
  fontSize: "14px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#919EAB14",
  },
}));

const getUserInitials = (user: User | null) => {
  if (!user || !user.email) return "";

  // Extract the email username before the "@" symbol
  const emailParts = user.email.split("@");
  const username = emailParts[0];

  // Split the username into parts and get the initials
  const nameParts = username.split(".");

  // Initialize initials variable
  let initials = "";

  // Loop through the name parts and get the first character of each
  for (const part of nameParts) {
    if (part.length > 0) {
      initials += part[0].toUpperCase();
    }
  }

  return initials;
};

export default function AccountPopover() {
  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null); // Ref for the BouncyIconBox
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!supabase) return;

      try {
        // Fetch current authenticated user
        const { data: authData, error: authError } =
          await supabase.auth.getUser();
        if (authError || !authData?.user?.id) {
          console.error("Error fetching user from Supabase:", authError);
          return;
        }

        // Set user profile
        setUserProfile(authData.user);

        // Fetch user role
        const userId = await getUserIdFromAuthId(authData.user.id);
        if (!userId) {
          console.error("User ID not found.");
          return;
        }

        // Fetch project memberships to get the role of the user
        const { data: projectMembersData, error: projectMembersError } =
          await supabase
            .from("ProjectMembers")
            .select("project_id, role")
            .eq("project_member", userId);

        if (projectMembersError) {
          console.error(
            "Error fetching project memberships:",
            projectMembersError
          );
          return;
        }

        // Fetch roles
        const { data: rolesData, error: rolesError } = await supabase
          .from("ProjectRoles")
          .select("*");

        if (rolesError) {
          console.error("Error fetching roles:", rolesError);
          return;
        }

        // Find the user's role for the current project (assuming the first project for simplicity)
        const userRoleData = projectMembersData[0];
        if (userRoleData) {
          const roleDetail = rolesData.find(
            (role) => role.id === Number(userRoleData.role)
          );
          if (roleDetail) {
            setUserRole(roleDetail.name);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    if (supabase) {
      try {
        setIsLoggingOut(true); // Set loading to true before initiating the logout
        const { error } = await supabase.auth.signOut();
        if (error) {
          console.error("Logout failed:", error.message);
        } else {
          console.log("Logout successful");
          navigate("/");
        }
      } finally {
        setIsLoggingOut(false); // Set loading to false regardless of success or failure
        handleClose();
      }
    }
  };

  return (
    <>
      <BouncyIconBox onClick={handleOpen} ref={anchorRef}>
        {/* Check if user profile data is available */}
        {userProfile && (
          <Typography
            variant="subtitle1"
            noWrap
            sx={{
              fontFamily: "Public Sans, sans-serif", // Set the font family as needed
              fontWeight: "bold", // Set the font weight to bold
            }}
          >
            {getUserInitials(userProfile)}
          </Typography>
        )}
      </BouncyIconBox>

      {open && (
        <Backdrop
          open={true}
          onClick={handleClose}
          style={{ zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <Popover
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                minWidth: 200,
                p: 0,
                borderRadius: "12px",
                backgroundColor: "#FFFFFF",
                zIndex: 10000,
                pt: 2,
                position: "absolute", // Set position to absolute to position the popover
                top: anchorRef.current
                  ? anchorRef.current.getBoundingClientRect().bottom
                  : 0, // Position the popover below the BouncyIconBox
                left: anchorRef.current
                  ? anchorRef.current.getBoundingClientRect().left
                  : 0, // Position the popover aligned with the left of the BouncyIconBox
              },
            }}
          >
            <Stack sx={{ p: 1 }}>
              {userProfile && (
                <Box sx={{ my: 0, px: 1.7 }}>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontFamily: "Public Sans, sans-serif",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {i18n.t("account.account-title")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontFamily: "Public Sans, sans-serif",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: "#637381",
                    }}
                  >
                    {userProfile.email}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontFamily: "Public Sans, sans-serif",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      fontSize: "14px",
                      mt: 1, // Margin top for spacing
                    }}
                  >
                    {i18n.t("account.account-user-admin")}
                  </Typography>
                  {userRole && (
                    <Typography
                      variant="subtitle1"
                      noWrap
                      sx={{
                        fontFamily: "Public Sans, sans-serif",
                        fontWeight: "normal",
                        fontStyle: "normal",
                        fontSize: "14px",
                        color: "#4F536E",
                        mt: 0.5, // Margin top for spacing
                      }}
                    >
                      {userRole}
                    </Typography>
                  )}
                </Box>
              )}

              <Divider sx={{ borderStyle: "dashed" }} />
              <br />

              <StyledLink
                href="https://elbwire.com/en/imprint/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Public Sans, sans-serif",
                    fontSize: 14,
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                >
                  {i18n.t("sidebar.imprint")}
                </Typography>
              </StyledLink>

              <StyledLink
                href="https://elbwire.com/en/app-privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: "Public Sans, sans-serif",
                    fontSize: 14,
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                >
                  {i18n.t("sidebar.privacy-policy")}
                </Typography>
              </StyledLink>
              <br />
              <Divider sx={{ borderStyle: "dashed" }} />

              <StyledLink
                onClick={handleLogout}
                sx={{
                  color: "#000000DE",
                  fontWeight: "fontWeightBold",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Public Sans, sans-serif",
                  fontStyle: "normal",
                  fontSize: "14px",
                }}
              >
                {isLoggingOut ? (
                  <img
                    src={Loading}
                    alt="Loading..."
                    style={{ width: 24, height: 24, marginRight: 10 }}
                  />
                ) : null}
                {isLoggingOut ? "Logging Out..." : i18n.t("account.logout")}
              </StyledLink>
            </Stack>
          </Popover>
        </Backdrop>
      )}
    </>
  );
}

const getUserIdFromAuthId = async (authId: string) => {
  try {
    if (!supabase) {
      console.error("Supabase is not initialized.");
      return null;
    }

    const { data } = await supabase
      .from("User")
      .select("user_id")
      .eq("auth_id", authId)
      .single();
    return data?.user_id || null;
  } catch (error: any) {
    console.error("Error fetching user ID:", error.message);
    return null;
  }
};
